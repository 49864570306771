<template>

    <div class="bloc-menu" ref="menumobile" v-if="reveler">

        <div class="sidebar-menu">
            <div class="positionbtnmenu">
                <div v-on:click="toggleMenuHamburger" class="btn-menu"> 
                    <svg role="img" viewBox="0 0 32 32">
                        <use xlink:href="../assets/sprite.svg#close"/>
                    </svg>
                </div> 
            </div>
            <div class="menusidebar">
                <nav>
                    <ul>
                        <li> <router-link to="/#presentation" v-on:click="toggleMenuHamburger"> Présentation </router-link> </li>
                        <li> <router-link to="/#creations" v-on:click="toggleMenuHamburger"> Nos réalisations </router-link> </li>
                        <li> <router-link to="/#quisommesnous" v-on:click="toggleMenuHamburger"> Qui sommes-nous ? </router-link> </li>
                        <li> <router-link to="/#avantapres" v-on:click="toggleMenuHamburger"> Avant Après </router-link> </li>
                        <li> <router-link to="/#temoignages" v-on:click="toggleMenuHamburger"> Témoignages </router-link> </li>
                        <li> <router-link to="/#contact" v-on:click="toggleMenuHamburger"> Contact </router-link> </li>
                    </ul>
                </nav>
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        name: 'MenuHamburger',
        props: ['reveler', 'toggleMenuHamburger']
    }
</script>

<style scoped>

    .bloc-menu {

        position: fixed;
        top: 0px;
        left: 0px;
        background: #333F50;
        width: 100vw;
        z-index: 20;

    }

    .menusidebar {

        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

    }

    .menusidebar ul {

        list-style: none;    
        
    }

    .menusidebar li {

        margin-top: 30px;
        
    }

    .menusidebar a {

        color: #ffffff;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;

    }

    .menusidebar a:hover{

        color: var(--color-tertiary);

    }

    .positionbtnmenu {

        position: absolute;
        right: 60px;
        top: 30px;
        z-index: 50;

    }

    .btn-menu {

        height: 40px;
        width: 40px;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .btn-menu svg {

        height: 20px;
        width: 20px;
        fill: var(--color-white);

    }

    .btn-menu:hover {

        background-color: var(--color-tertiary);

    }

    @media screen and (max-width: 580px) {

        .positionbtnmenu {

            position: absolute;
            right: 50px;

        }

    }

    @media screen and (max-width: 450px) {

        .btn-menu {

            height: 30px;
            width: 30px;
            border-radius: 15px;

        }

        .btn-menu svg {

            height: 14px;
            width: 14px;

        }

        .menusidebar a {

            font-size: 12px;

        }

        .positionbtnmenu {

            position: absolute;
            right: 40px;
            top: 24px;

        }

    }

</style>