<template>

    <header>
        <h2>{{ titre }}</h2>
    </header>

</template>

<script>

    export default {
        name: 'TitresSections',
        props: ['titre']
    }
    
</script>

<style scoped>

    header > h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    header > h2::before,
    header > h2::after {
        content: "";
        display: block;
        height: var(--h2-underlying-height);
        background-color: var(--color-secondary);
        width: 20rem;
    }

    header > h2::before {
        margin-right: 8rem;
    }

    header > h2::after {
        margin-left: 8rem;
    }

    @media screen and (max-width: 500px) {

        header > h2::before,
        header > h2::after {
            width: 15rem;
        }

    }

    @media screen and (max-width: 400px) {

        header > h2::before,
        header > h2::after {
            width: 10rem;
        }

        header > h2::before {
            margin-right: 2rem;
        }

        header > h2::after {
            margin-left: 2rem;
        }

    }

</style>