<template>

    <section class="social-proof" id="temoignages">

        <header>
            <h2>Témoignages</h2>
        </header>

        <article>
            <section class="quotes js-reveal-left">
                <p>"Artisan appliqué et minutieux"</p>
                <img alt="photo-décoration-plafond" src="../assets/siou.png">
                <p>"L'amour du travail bien fait"</p>
            </section>

            <section class="reviews js-reveal-right">
                <cartes-temoignages class="review" prenom="Claudine" description="“Nous avons fait appel à cet artisan pour refaire l'isolation. Ce dernier effectue un travail soigné et de qualité. (...) d'excellent conseil pour tout ce qui touche à la rénovation.”" post="Posté sur" imgURL="googlecarte.svg" imgEtoiles="etoiles.svg"></cartes-temoignages>
                <cartes-temoignages class="review" prenom="Lionel" description="“Nous avons fait appel à l'entreprise pour une rénovation d’une longère. (...) De très bons conseils, travail parfait et qui plus est une personne sympathique. A recommander ! ”" post="Posté sur" imgURL="googlecarte.svg" imgEtoiles="etoiles.svg"></cartes-temoignages>
                <cartes-temoignages class="review" prenom="Guy" description="“Nous recommandons Iso Plac Deco pour tous types de travaux (...). Il a le souci de la conscience professionnelle, travaille dans des délais rapides et le rapport qualité/prix est très bon.”" post="Posté sur" imgURL="googlecarte.svg" imgEtoiles="etoiles.svg"></cartes-temoignages>
            </section>
        </article>

        <footer class="js-reveal-bottom">
            <a href="https://www.google.com/search?q=iso+plac+deco&rlz=1C1NDCM_frFR768FR768&oq=iso+plac+deco&aqs=chrome..69i57j46i175i199i512j35i39j0i10i22i30j69i64j69i60l3.4266j0j7&sourceid=chrome&ie=UTF-8#lrd=0x480f1f53d14b8491:0x3b581ca6055abcef,3,,," id="boutonavis" rel="noopener noreferrer" target="_blank" >
                <button>
                    <img alt="logo-maison-google" src="../assets/business.svg" />               
                    <p> Laissez un avis ! </p>
                </button>              
            </a>
        </footer>

    </section>

</template>

<script>

import CartesTemoignages from '../componentschild/CartesTemoignages.vue';
import ScrollReveal from 'scrollreveal';

export default {
    
    name: 'Temoignages',
    components: {
        CartesTemoignages
    },

    mounted() {
        const revealLeft = {
            distance: '50%',
            delay: '600',
            duration: '1400',
            easing: 'cubic-bezier(.73, .35, .44, .9)',
            origin: 'left'
        };

        const revealRight = {
            distance: '50%',
            delay: '600',
            duration: '1400',
            easing: 'cubic-bezier(.73, .35, .44, .9)',
            origin: 'right'
        };

        const revealBottom = {
            distance: '50%',
            delay: '600',
            duration: '1400',
            easing: 'cubic-bezier(.73, .35, .44, .9)',
            origin: 'bottom'
        };

        ScrollReveal().reveal('.js-reveal-left', revealLeft);
        ScrollReveal().reveal('.js-reveal-right', revealRight);
        ScrollReveal().reveal('.js-reveal-bottom', revealBottom);
    }

}

</script>

<style scoped>

    .social-proof {
        display: flex;
        flex-direction: column;
        padding: var(--padding-top-bottom-section) var(--padding-left-right-section);
        overflow: hidden;
    }

    .social-proof > header {
        display: inline-flex;
        flex-direction: column;
        align-self: flex-end;
    }

    .social-proof > header > h2::after {
        content: "";
        display: block;
        background-color: var(--color-secondary);
        width: 100%;
        height: var(--h2-underlying-height);
        margin-top: 4rem;
    }

    .social-proof > article {
        display: flex;
        align-items: stretch;
        padding-top: var(--spacing-top-big);
    }

    .quotes, .reviews {
        width: 50%;
    }

    .quotes {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .quotes > * {
        width: 80%;
    }

    .quotes > img {
        margin: var(--spacing-top-medium) 0;
    }

    .quotes > p {
        font-size: var(--font-size-big);
        font-weight: var(--font-weight-semibold);
        text-align: center;
    }

    .reviews {
        position: relative;
    }

    .social-proof > footer {
        display: flex;
        justify-content: center;
        padding-top: 28rem;
    }

    .marketplace-icon {
        height: var(--font-size-big);
        width: var(--font-size-big);
        margin-right: 1rem;
        fill: var(--color-white);
    }

    .review {
        position: absolute;
        
    }

    .review:nth-child(1) {
        position: relative;
    
    }

    .review:nth-child(2) {
        top: calc(var(--review-card-min-height) + 10rem);
    }

    .review:nth-child(3) {
        top: 50%;
        transform: translate(0, -50%);
        left: calc(var(--review-card-width) + 5rem);
    }

    .footer > img {
        height: 20px;
        width: 20px;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 2.5rem;
	background-color: var(--color-secondary);
	border-radius: var(--border-radius-btn);
	color: var(--color-white);
	font-weight: var(--font-weight-bold);
		font-size: var(--font-size-big);                                    
        cursor: pointer;
        transition: background-color 0.2s;
    }

    button:hover {
        background-color: var(--color-tertiary);
    }

    button:active {
        transform: scale(0.99);
    }

    button p {
        margin-left: 20px;
    }

    @media screen and (max-width: 1700px) {

        .social-proof > footer {
            padding-top: 30rem;
        }

    }

    @media screen and (max-width: 1600px) {

        .social-proof > footer {
            padding-top: 32rem;
        }

    }

    @media screen and (max-width: 1000px) {

        .review:nth-child(3) {
            left: calc(var(--review-card-width) + 7rem);
        }

    }

    @media screen and (max-width: 850px) {

        .social-proof {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .social-proof > header {
            width: 100%;
            align-items: center;
            text-align: center;
        }

        .social-proof > header > h2 {
            width: 80%;
        }

        .social-proof > article {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: var(--spacing-top-big);
        }

        .quotes {
            width: 100%;
        }

        .reviews {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 8rem;
        }

        .review {
            position: unset;
            width: calc((100% - 2rem * 2) / 3);
        }

        .review:nth-child(2) {
            top: unset;
        }

        .review:nth-child(3) {
            top: unset;
            transform: unset;
            left: unset;
        }

        .social-proof > footer {
            padding-top: 8rem;
        }

        .footer > img {
            height: 14px;
            width: 14px;
        }

    }

    @media screen and (max-width: 700px) {

        .review {
            width: calc((100% - 6rem) / 2);
        }

        .review:nth-child(3) {
            display: none;
        }

    }

    @media screen and (max-width: 650px) {

        .social-proof > header > h2 {
            width: 100%;
        }

        .quotes > * {
            width: 100%;
        }

    }

    @media screen and (max-width: 530px) {

        .reviews {
            flex-direction: column;
        }

        .review {
            width: 70%;
        }

        .review:nth-child(3) {
            display: unset;
        }

        .review:not(:first-child) {
            margin-top: 2rem;
        }
        
    }

    @media screen and (max-width: 350px) {

        .review {
            width: 80%;
        }

    }

</style>