<template>

    <section class="contact" id="contact">

        <header>
            <titres-sections titre="Contact"></titres-sections>
        </header>

        <section>

            <section class="details js-reveal-left">

                <article class="contact-item">
                    <header>
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#phone"/>
                        </svg>
                        <span class="title">Par téléphone</span>
                    </header>
                    <div>
                        06 50 98 31 19
                    </div>
                </article>

                <article class="contact-item">
                    <header>
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#email"/>
                        </svg>
                        <span class="title">Par mail</span>
                    </header>
                    <div>
                        ipdeco35@gmail.com
                    </div>
                </article>

                <article class="contact-item">
                    <header>
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#address"/>
                        </svg>
                        <span class="title">Notre adresse</span>
                    </header>
                    <div class="address">
                        4 Square de l’Abbé Pierre
                        <br>
                        35 170 Bruz
                        <br>
                        France
                    </div>
                </article>

                <article class="contact-item">
                    <header>
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#watch"/>
                        </svg>
                        <span class="title">Horaires d'ouverture</span>
                    </header>

                    <div class="openings">
                        <section class="days">
                            <span>Lun :</span>
                            <span>Mar :</span>
                            <span>Mer :</span>
                            <span>Jeu :</span>
                            <span>Ven :</span>
                            <span>Sam :</span>
                            <span>Dim :</span>
                        </section>

                        <section class="hours">
                            <span>08:00 - 19:00</span>
                            <span>08:00 - 19:00</span>
                            <span>08:00 - 19:00</span>
                            <span>08:00 - 19:00</span>
                            <span>08:00 - 19:00</span>
                            <span>08:00 - 19:00</span>
                            <span>Fermé</span>
                        </section>
                    </div>
                </article>

            </section>

            <section class="map js-reveal-right">
                <!-- <img src="../assets/carte.png" alt="Map illustration"> -->
                <GMapMap
                    :center="center"
                    :zoom="14"
                    map-type-id="terrain"
                    style="width: 100vw; height: 100vw"
                    :options="{
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    }"
                >
                    <GMapMarker
                        :key="index" 
                        v-for="(m, index) in markers" :position="m.position"
                        @click="openMarker(m.id)"
                    >
                    <GMapInfoWindow
                        :closeclick="true"
                        @closeclick="openMarker(null)"
                        :opened="openedMarkerID === m.id"
                    >
                        <div> Iso Plac Deco </div>
                    </GMapInfoWindow>
                    </GMapMarker>
                </GMapMap>
            </section>
        </section>

        <footer class="js-reveal-bottom">
            <a href="https://www.google.com/maps/dir//Iso+Plac+Deco,+4+Sq.+de+l'Abb%C3%A9+Pierre,+35170+Bruz/@48.0263619,-1.7569365,17z/data=!4m16!1m6!3m5!1s0x480f1f53d14b8491:0x3b581ca6055abcef!2sIso+Plac+Deco!8m2!3d48.0263619!4d-1.7547478!4m8!1m0!1m5!1m1!1s0x480f1f53d14b8491:0x3b581ca6055abcef!2m2!1d-1.7547478!2d48.0263619!3e0" rel="noopener noreferrer" target="_blank">
                <bouton>
                    Itinéraire
                </bouton>
            </a>
        </footer>

    </section>

</template>

<script>
import Bouton from '../componentschild/Boutons.vue';
import TitresSections from '../componentschild/TitresSections.vue';
import ScrollReveal from 'scrollreveal';

export default {
    
    name: 'Temoignages',
    components: {
        Bouton, TitresSections
    },
    data() {
        return {

            openedMarkerID: null,
            
            center: {lat: 48.02645515833548, lng: -1.754779988491788}, 

            markers: [{
            id:'1',
            position: {
            lat: 48.02645515833548, lng: -1.754779988491788
            },
            }]
            
        }
    },
    methods: {
        openMarker(id) {
        this.openedMarkerID = id
    }
    },
    mounted() {
        const revealLeft = {
            distance: '50%',
            delay: '600',
            duration: '1400',
            easing: 'cubic-bezier(.73, .35, .44, .9)',
            origin: 'left'
        };

        const revealRight = {
            distance: '50%',
            delay: '600',
            duration: '1400',
            easing: 'cubic-bezier(.73, .35, .44, .9)',
            origin: 'right'
        };

        const revealBottom = {
            distance: '50%',
            delay: '600',
            duration: '1400',
            easing: 'cubic-bezier(.73, .35, .44, .9)',
            origin: 'bottom'
        };

        ScrollReveal().reveal('.js-reveal-left', revealLeft);
        ScrollReveal().reveal('.js-reveal-right', revealRight);
        ScrollReveal().reveal('.js-reveal-bottom', revealBottom);
    }
}
</script>

<style scoped>

    .contact {
        padding: var(--padding-top-bottom-section) var(--padding-left-right-section);
    }

    .contact > section {
        display: flex;
        align-items: center;
        justify-content: space-between;
		padding-top: var(--spacing-top-big);
        overflow: hidden;
    }

    .details, .map {
        width: var(--width-section-contact);
    }

    .details {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
    }

    .contact-item {
        width: calc((100% - 4rem) / 2);
    }

    .contact-item:nth-child(n + 3) {
        margin-top: 4rem;
    }

    .contact-item > header {
        display: flex;
        align-items: center;
    }

    .contact-item svg {
        width: var(--contact-icon-size);
        height: var(--contact-icon-size);
        fill: var(--color-secondary)
    }

    .contact-item .title {
        font-size: var(--font-size-big);
        font-weight: var(--font-weight-semibold);
        margin-left: 2rem;
    }

    .contact-item > div {
        padding-top: var(--margin-left-contact-item-title);
        padding-left: calc(var(--contact-icon-size) + var(--margin-left-contact-item-title));
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
    }

    .openings {
        display: flex;
    }

    .days, .hours {
        display: flex;
        flex-direction: column;
    }

    .hours {
        padding-left: 1rem;
    }

    .map {
        height: 60vh;
    }

    .vue-map-container {
        height: 100%;
        width: 100%;
    }

    .contact footer {
        display: flex;
        justify-content: center;
        width: var(--width-section-contact);
        margin-left: auto;
        padding-top: 4rem;
    }

    @media screen and (max-width: 1100px) {

        .map {
            height: 50vh;
        }

    }

    @media screen and (max-width: 850px) {

        .contact > section {
            flex-direction: column;
        }

        .details {
            width: 65%;
        }

        .map {
            width: 95%;
            margin-top: 6rem;
            height: 55vh;
        }

        .contact > footer {
            width: 100%;
        }

    }

    @media screen and (max-width: 650px) {

        .details {
            width: 80%;
        }

    }

    @media screen and (max-width: 550px) {

        .map {
            height: 50vh;
            width: 90%;
        }

    }

    @media screen and (max-width: 500px) {
        .details {
            width: 100%;
        }

    }


    @media screen and (max-width: 530px) {

        .details {
            flex-direction: column;
            align-items: center;
            flex-wrap: unset;
            justify-content: unset;
        }

        .contact-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .contact-item:nth-child(n + 2) {
            margin-top: 6rem;
        }

        .contact-item > div {
            padding-top: 1rem;
            padding-left: unset;
        }

        .address {
            text-align: center;
        }

        .map {
            width: 100%;
        }

    }

</style>