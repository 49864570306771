<template>

<div class="boutonsfixe">

    <a href="tel:0650983119"> 
        <div class="telephonefixe">
                <div class="blocfixe">
                    <svg role="img" viewBox="0 0 32 32">
                        <use xlink:href="../assets/sprite.svg#phone"/>
                    </svg>     
                </div>
            <div class="appelez">
                <div id="appelez">
                    <p> APPELEZ </p>
                </div>
            </div>
        </div>
    </a>

    <a v-on:click="toggleModale"> 
        <div class="devisfixe">
            <div class="blocfixe">
                <svg role="img" viewBox="0 0 32 32">
                    <use xlink:href="../assets/sprite.svg#email"/>
                </svg> 
            </div>
            <div class="devis">
                <p> DEVIS </p>
            </div> 
        </div>
    </a> 

    <!-- <demande-devis v-bind:revele="revele" v-bind:toggleModale="toggleModale"></demande-devis> -->
    <demande-devis v-if="revele" v-bind:toggleModale="toggleModale"></demande-devis>
</div>

</template>

<script>

import DemandeDevis from '../componentschild/DemandeDevis.vue';

export default {

    name: 'BoutonsFixes',
    data() {
        return {
            revele: false,
        }
    },
    components: {
        DemandeDevis,
    },
    methods: {
        toggleModale(){
            this.revele = !this.revele
        }
    }

}

</script>

<style scoped>

.boutonsfixe {

    position: fixed;
    right: 0%;
    top: 50%;
    z-index: 10;

}

.telephonefixe {

    height: 40px;
    width: 90px;
    background-color: #7C928F;
    display: flex;

}

.telephonefixe svg  {

    height: 20px;
    width: 20px;
    background-color: #2c3e50;

}

.appelez {

    color: white;
    font-weight: bold;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.devisfixe {

    height: 40px;
    width: 90px;
    background-color: #D3785B;
    display: flex;
    margin-top: 30px;

}

.devisfixe svg {

    height: 20px;
    width: 20px;
    background-color: #2c3e50;

}

.devis{

    color: white;
    font-weight: bold;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.blocfixe {

    height: 40px;
    width: 40px;
    background-color: #2c3e50;
    display: flex;
    justify-content: center;
    align-items: center;

}

.blocfixe svg {

    fill: var(--color-white);

}

@media screen and (max-width: 1280px) {

    .telephonefixe {

        width: 50px;
        height: 50px;
        display: flex;
        flex-direction: column;

    }

    .devisfixe {

        width: 50px;
        height: 50px;
        display: flex;
        flex-direction: column;

    }

    .blocfixe {

        width: 50px;

    }


    .appelez {

        height: 25px;
        width: 100%;
        
    }

    .devis {

        height: 25px;
        width: 100%;
        
    }

}

@media screen and (max-width: 1100px) {

    .telephonefixe {

        width: 44px;
        height: 44px;
        display: flex;
        flex-direction: column;

    }

    .devisfixe {

        width: 44px;
        height: 44px;
        display: flex;
        flex-direction: column;

    }

    .blocfixe {

        width: 44px;

    }


    .appelez {

        height: 20px;
        width: 100%;
        
    }

    .devis {

        height: 20px;
        width: 100%;
        
    }

}

@media screen and (max-width: 1050px) {

    .telephonefixe {

        width: 26px;
        height: 30px;

    }

    .devisfixe {

        width: 26px;
        height: 30px;

    }

    .blocfixe {

        width: 26px;

    }

    .appelez {

        height: 16px;
        width: 100%;
        
    }

    .devis {

        height: 16px;
        width: 100%;
        
    }

    .appelez p {

        font-size: 4.5px;

    }

    .devis p {

        font-size: 5px;

    }

    .blocfixe svg {

        width: 13px;

    }

}

@media screen and (max-width: 450px) {

    .telephonefixe {

        height: 20px;
        width: 20px;

    }

    .devisfixe {
        
        height: 20px;
        width: 20px;

    }

    .blocfixe {

        width: 20px;

    }

    .blocfixe svg {

        width: 12px;

    }

    .appelez {

        display: none;

    }

    .devis {

        display: none;
        
    }

}

</style>