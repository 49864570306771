import { defineAsyncComponent } from 'vue';
import { createRouter, createWebHistory} from 'vue-router';

import pageprincipale from '@/pageprincipale/pageprincipale.vue';

// import connexion from '@/admin/connexion.vue';
// import dashboard from '@/admin/dashboard.vue';
//import amenagement from '@/pagesdetails/amenagement.vue';
// import fauxPlafonds from '@/pagesdetails/fauxPlafonds';
// import isolation from '@/pagesdetails/isolation.vue';
// import meubles from '@/pagesdetails/meubles.vue';
// import menuiseries from '@/pagesdetails/menuiserie.vue';
// import pageerreur from '@/pageerreur/notfound.vue';

const connexion = defineAsyncComponent(() => import('@/admin/connexion.vue'));
const dashboard = defineAsyncComponent(() => import('@/admin/dashboard.vue'));
const amenagement = defineAsyncComponent(() => import('@/pagesdetails/amenagement'));
const fauxPlafonds = defineAsyncComponent(() => import('@/pagesdetails/fauxPlafonds'));
const isolation = defineAsyncComponent(() => import('@/pagesdetails/isolation'));
const meubles = defineAsyncComponent(() => import('@/pagesdetails/meubles'));
const menuiseries = defineAsyncComponent(() => import('@/pagesdetails/menuiserie'));
const pageerreur = defineAsyncComponent(() => import('@/pageerreur/notfound.vue'));

const routes = [

    {
        name: 'connexion',
        path: '/connexion',
        component: connexion,
    },

    {
        name: 'dashboard',
        path: '/dashboard',
        component: dashboard,
    },

    {
        name: 'pageprincipale',
        path: '/',
        component: pageprincipale,
    },

    {
        name: 'fauxPlafonds',
        path: '/fauxplafonds',
        component: fauxPlafonds,
    },

    {
        name: 'amenagement',
        path: '/amenagement',
        component: amenagement,
    },

    {
        name: 'isolation',
        path: '/isolation',
        component: isolation,
    },

    {
        name: 'meubles',
        path: '/meubles',
        component: meubles,
    },

    {
        name: 'menuiseries',
        path: '/menuiseries',
        component: menuiseries,
    },

    {
        name: 'pageerreur',
        path: '/:catchAll(.*)',
        component: pageerreur,
    }

];

const router = createRouter({

    history: createWebHistory(),
    
    routes, 

    scrollBehavior(to, from, savedPosition) {
        console.log(to);
        console.log(from);
        console.log(savedPosition);
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash };
        }
    },   

})

export default router;

