<template>

    <article>

        <header>
            <span class="name">{{ prenom }}</span>
            <img class="stars" :src="require(`../assets/${imgEtoiles}`)" alt="Etoiles">
        </header>

        <p class="description">{{ description }}</p>

        <footer>
            <span>{{ post }}</span>
            <img alt="logo-google" :src="require(`../assets/${imgURL}`)">
        </footer>

    </article>

</template>

<script>

    export default {
    
        name: 'Temoignages',
        props: { prenom: String, description: String, post: String, imgURL: String, imgEtoiles: String } 

    }

</script>

<style scoped>

    article {
        width: var(--review-card-width);
        min-height: var(--review-card-min-height);
        padding: 2rem 3rem;
        box-shadow: 0px 4px 12px #333F50;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .name {
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-bold);
    }

    .stars {
        width: 50%;
    }

    .description {
        padding-top: 2rem;
        font-size: 1.4rem;
        line-height: var(--line-height-base);
    }

    footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
    }

    footer > span {
        font-size: 1.2rem;
        font-weight: var(--font-weight-bold);
        color: var(--color-secondary);
        padding-bottom: 1rem;
    }

</style>