<template>
    <section class="avantapres" id="avantapres">
        <titres-sections id="titreavantapres" titre="Avant Après"></titres-sections>
        <article class="contenuavantapres">

                <div class="avantapres1">
                    <cartes-avant-apres  alt="image-pose de plaques de plâtre" imgav="etageav.png" imgap="etageap.png"></cartes-avant-apres>
                    <cartes-avant-apres  alt="image-pose isolation et raillage" imgav="escalierav.png" imgap="escalierap.png" ></cartes-avant-apres>
                </div>
                <div class="avantapres2">
                    <cartes-avant-apres alt="image-aménagement de combles" imgav="combleav.png" imgap="combleap.png"></cartes-avant-apres>
                    <cartes-avant-apres alt="image-pose de cloison" imgav="portesav.png" imgap="portesap.png"></cartes-avant-apres>
                </div>
                <div class="avantapres3">
                    <cartes-avant-apres alt="image-chambre enfant" imgav="chambreenfantav.png" imgap="chambreenfantap.png"></cartes-avant-apres>
                    <cartes-avant-apres alt="image-salle de bain" imgav="sallebainav.png" imgap="sallebainap.png"></cartes-avant-apres>
                </div>
                

        </article>
    </section>
</template>

<script>
import TitresSections from '../componentschild/TitresSections.vue'
import CartesAvantApres from '../componentschild/CartesAvantApres.vue'

export default {
    name: "AvantApres",
    components: { TitresSections, CartesAvantApres},
}
</script>

<style scoped>

.avantapres {

    padding: 10rem 10rem 2rem 10rem ;

}

article {

	padding-top: var(--spacing-top-cartesavap);

}

.avantapres1 {

    display: flex;
    justify-content: space-evenly;

}

.avantapres2 {

    display: flex;
    justify-content: space-evenly;
    padding-top: 40px;

}

.avantapres3 {

    display: flex;
    justify-content: space-evenly;
    padding-top: 46px;

}

@media screen and (max-width: 900px) {

    .avantapres {

        display: flex;
        flex-direction: column;
        align-items: center;

    }


    .avantapres1 {

        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .avantapres2 {

        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;

    }

    .avantapres3 {

        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;

    }

}

@media screen and (max-width: 450px) {

    .avantapres {

        padding-left: 20px;
        padding-right: 20px;

    }

}

</style>