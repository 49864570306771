<template>

    <div>
      <barre-navigation></barre-navigation>
      <accueil></accueil>
      <boutons-fixes></boutons-fixes>
      <creations></creations>
      <qui-nous-sommes></qui-nous-sommes>
      <avant-apres></avant-apres>
      <temoignages></temoignages>
      <contact></contact>
      <footer-page></footer-page>
    </div>

</template>

<script>

  import BarreNavigation from '../components/BarreNavigation.vue'
  import Accueil from '../components/Accueil.vue'
  import BoutonsFixes from '../components/BoutonsFixes.vue'
  import Creations from '../components/Creations.vue'
  import QuiNousSommes from '../components/QuiNousSommes.vue'
  import AvantApres from '../components/AvantApres.vue'
  import Temoignages from '../components/Temoignages.vue'
  import Contact from '../components/Contact.vue'
  import FooterPage from '../components/FooterPage.vue'


  export default {

    name: 'pageprincipale',
    components: {

      BarreNavigation,
      Accueil,
      BoutonsFixes,
      Creations,
      QuiNousSommes,
      AvantApres,
      Temoignages,
      Contact,
      FooterPage,
      
    }

  }

</script>