<template>

    <div class="blocavantapres">
    
        <div class="blocslider">
            <div v-on:click="bougecurseur" class="slider slider--rond">
                <div class="slider__avant"><img alt="image-avant" :src="require(`../assets/${imgav}`)"></div>
                <div class="slider__separator"></div>
                <div class="slider__apres"><img alt="image-apres" :src="require(`../assets/${imgap}`)"></div>
                <input class="slider__range" type="range" min="0" max="100" v-model="value" />
            </div>
        </div>   
        
    </div>  
    
</template>

<script>

export default {
    name: "CartesAvantApres",
    props: {imgap: String, imgav: String},
    data() {
        return {
        value: 50
        }
    },

    methods: {

        bougecurseur() {

            let sliders = document.querySelectorAll('.slider');

            sliders.forEach(function (slider) {

                let sliderRange = slider.querySelector('.slider__range');
                let sliderBefore = slider.querySelector('.slider__avant');
                let sliderSeparator = slider.querySelector('.slider__separator');

                function updateSliderPosition() {
                    sliderBefore.style = `width:${sliderRange.value}%` ;
                    sliderSeparator.style = `left:${sliderRange.value}%` ;
                    console.log('dsdf');
                }

                if(sliderRange){
                    sliderRange.addEventListener('input', updateSliderPosition);
                }

                let isDragging = false;

                if (sliderSeparator) {
                    sliderSeparator.addEventListener('mousedown',
                    function () {
                        isDragging = true;
                    });
                }

                if (sliderSeparator) {
                    sliderSeparator.addEventListener('touchstart', function() {
                        isDragging = true;
                    });
                }

                document.addEventListener('mouseup', function () {
                    isDragging = false;
                });

                document.addEventListener('touchend', function() {
                    isDragging = false;
                });

                document.addEventListener('mousemove', function (e) {
                    processMove(e.clientX);
                });

                document.addEventListener('touchmove', function (e) {
                    processMove(e.touches[0].clientX);
                });

                function processMove(x) {
                    if (isDragging) {
                        let sliderRect = slider.getBoundingClientRect();
                        let newWidth = (x - sliderRect.left) /
                        sliderRect.width * 100;
                        sliderRange.value = newWidth;
                        updateSliderPosition();
                    }
                }

            });
        }
    }
}

</script>


<style scoped>

    .blocavantapres {

        width: 400px;
        height: 500px;

    }

    .blocslider {

        position: relative;
        height: 533px;
        width: 100%;

    } 

    .slider {

        position: relative;
        height: 426px;
        width: 320px;
        overflow: hidden;
        margin: auto;

    }

    .slider__apres {

        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;

    }

    .slider__avant {

        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        width: 50%;
        height: 100%;
        overflow: hidden;

    }

    .slider__avant img {

        background-size: cover; 
        height: 100%;

    }

    .slider__apres img {

        background-size: cover; 
        height: 100%;

    }


    .slider__avant:before {

        content:'Avant';
        position: absolute;
        left: 8px;
        top: 8px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
        margin: 0px;
        
    }

    .slider__separator {

        position: absolute;
        left: 50%;
        width: 2px;
        top: 0px;
        bottom: 0px;
        background: white;
        z-index: 3;
        box-shadow: 0 5px 10px 0px rgba(0,0,0, 0.5);
        cursor: ew-resize;
        transform: translateX(-50%);
        
    }

    .slider__apres:before {

        content:'Après';
        position: absolute;
        right: 8px;
        top: 8px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
        margin: 0px;

    }

    .slider__range {

        position: absolute;
        width: 100%;
        bottom: 0px;
        z-index: 3;
        appearance: none;
        background: rgba(255,255,255,0.3);
        outline: none;
        margin: 0px;

    }

    .slider__range::-webkit-slider-thumb {

        -webkit-appearance: none;
        width: 12px;
        height: 16px;
        background: white;

    }

    .slider__range::-moz-slider-thumb {

        -webkit-appearance: none;
        width: 12px;
        height: 16px;
        background: white;

    }

    .slider--rond .slider__separator:before {

        content: url('');
        background-image: url('../assets/arrowbouge.svg');
        background-size: 26px 48px ;
        background-repeat: no-repeat;
        background-position: 64% 26%;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: solid 3px white; 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
    }

    .slider--rond .slider__range {

        display: none;

    }

    .slider--rond .slider__avant:before,
    .slider--rond .slider__apres:before {

        top: 6%;
        background: #7C928F;
        opacity: 0.8; 
        padding: 8px 16px;
        border-radius: 8px;
        margin-left: 50px;
        margin-right: 50px;

    }


    @media screen and (max-width: 900px) {

        .slider {

            height: 75%;
            width: 75%;

        }

        .blocavantapres {

            height: 400px;
            margin-bottom: 100px;
            
        }

        .blocavantapres p {

            font-size: 16px;
            padding-bottom: 40px;

        }

    }


    @media screen and (max-width: 530px) {

        .slider {

            height: 65%;
            width: 65%;

        }

        .blocavantapres {

            height: 350px;
            margin-bottom: 100px;
            
        }

        .blocavantapres p {

            font-size: 16px;

        }

        .slider--rond .slider__avant:before,
        .slider--rond .slider__apres:before {

            margin-left: 35px;
            margin-right: 35px;

        }

    }

    @media screen and (max-width: 430px) {

        .blocslider {

            height: 300px;

        }

        .slider {

            width: 215px;
            height: 95%;

        }

        .blocavantapres {
            
            height: 280px;
            width: 100%;
            
        }

        .slider__avant img,
        .slider__apres img {

            width: 215px;

        }

    }

</style>
