<template>

  <div class="body">
    <div>
      <router-view></router-view>
    </div>
  </div>

</template>

<script>

  export default {

    name: 'App',
    
  }

</script>

<style>

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

  @font-face {
    font-family : 'Beautyous';
    src : url(./fonts/BeautyousDemoBold.ttf);
  }

  :root {

    --color-primary: #2c3e50;
    --color-secondary: #7C928F;
    --color-tertiary: #D3785B;
    --color-tertiary-dark: #b8674e;
    --color-error: #e70b0b;
    --color-success: #338d58;
    --color-grey: #EFF0F6;
    --color-black: #000;
    --color-white: #FFF;
    --color-background-modal: rgba(0, 0, 0, 0.6);

    --color-error-light: #ffcaca;
    --color-success-light: #c2ffdb;

    /* FONT SIZE */
    --font-size-base: 1.5rem;
    --font-size-big: 2.5rem;
    --font-size-medium: 2rem;
    --font-size-h1: 4rem;
    --font-size-h2: 3rem;

    /* LINE HEIGHT */
    --line-height-base: 2rem;
    --line-height-pagesdetails: 2.4rem;
    --line-height-medium: 3rem;
    --line-height-big: 3.5rem;

    /* FONT WEIGHT */
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    /* NAVBAR */
    --nav-max-height: 14rem;

    /* PADDING | MARGIN */
    --padding-top-bottom-section: 10rem;
    --padding-haut-bas-section:5rem;
    --padding-left-right-section: 20rem;
    --padding-gauche-droite-section: 20rem;
    --spacing-top-cartesavap: 16rem;
    --spacing-top-big: 8rem;
    --spacing-top-medium: 4rem;

    /* BORDER RADIUS */
    --border-radius-btn: 10rem;
    --border-radius-round: 100%;

    /* UTILITIES */
    --h1-underlying-height: 1rem;
    --h2-underlying-height: 0.4rem;

    /* SECTION REVIEWS */
    --review-card-width: 28rem;
    --review-card-min-height: 20rem;

    /* SECTION CONTACT */
    --width-section-contact: calc(50% - 5rem);
    --margin-left-contact-item-title: 2rem;
    --contact-icon-size: 3rem;

    /* FOOTER */
    --footer-icon-size: 3rem;

  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
    color: unset;
    cursor: pointer;
  }

  button, input, textarea {
    border: none;
    border-radius: 0; 
    -webkit-appearance: none;
    background-color: unset;
    outline-offset: unset;
    outline: unset;
    font-family: inherit;
  }

  input:focus, input:active {
    outline: none;
    border: none;
  }

  textarea {
    resize: none;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%; /* 1rem = 10px */
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    color: var(--color-primary);
  }

  h1, h2 { font-weight: var(--font-weight-bold); }

  h1 { font-size: var(--font-size-h1); }
  h2 { font-size: var(--font-size-h2); }

  @media screen and (max-width: 1600px) {
    :root {
      --padding-left-right-section: 10rem;
      --font-size-big: 2rem;
      --font-size-medium: 1.8rem;
      --line-height-big: 3rem;
    }
  }

  @media screen and (max-width: 1400px) {
    html {
      font-size: 55.5%;
    }
  }

  @media screen and (max-width: 1200px) {
    html {
      font-size: 50.5%;
    }
  }

  @media screen and (max-width: 1100px) {
    html {
      font-size: 45.5%;
    }
  }

  @media screen and (max-width: 1000px) {
    html {
      font-size: 40.5%;
    }
  }

  @media screen and (max-width: 450px) {
    :root {
      --padding-left-right-section: 2rem;
      --spacing-top-big: 4rem;
    }
  }

</style>
