<template>

        <div v-show="visibleSlide === index" class="carrouselimg">
            <slot></slot> 
        </div>

</template>

<script>
    export default {
        name: 'CarrouselSlide',
        props: ['visibleSlide', 'index'],
        data() {
            return {

            }
        }
    }
</script>

<style scoped>

    .carrouselimg {

        display: flex;
        justify-content: center;
        align-items: center;

    }

</style>