<template>

    <button :type="type"> 
        <slot> </slot>
    </button>

</template>

<script>
export default {
    props: {

      type: String,

    }
}
</script>

<style scoped>

  button {

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 2.5rem;
		background-color: var(--color-secondary);
		border-radius: var(--border-radius-btn);
		color: var(--color-white);
		font-weight: var(--font-weight-bold);
		font-size: var(--font-size-big);
    cursor: pointer;
    transition: background-color 0.2s;

  }

  button:hover {

    background-color: var(--color-tertiary);

  }

  button:active {

    transform: scale(0.99);
    
  }

</style>
