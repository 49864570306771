<template>

    <section class="who" id="quisommesnous">

        <header>
            <h2>Qui sommes-nous ?</h2>
        </header>

        <article class="js-reveal-bottom">
            <p class="description">
                <span>Iso Plac Deco</span> réalise l’isolation thermique (BBC) et acoustique, la pose de 
                cloisons sèches, plafonds et faux-plafonds, les bandes à joint. Nous sommes 
                également spécialisés dans les travaux d’agencement intérieur, l’aménagement 
                de combles, la réalisation de meubles et éléments décoratifs sur mesure.
            </p>
            <iframe src="https://www.youtube.com/embed/2TRDdcXvci0" title="Iso Plac Deco" frameborder="0" allowfullscreen></iframe>
            
            <div class="details">
                <div>
                    <router-link to="/isolation" class="cta">
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#arrow_right"/>
                        </svg>
                        <span> Isolation placo </span>
                    </router-link>
                </div>
                
                <div>
                    <router-link to="/amenagement" class="cta">
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#arrow_right"/>
                        </svg>
                        <span> Aménagement de combles </span>
                    </router-link>
                </div>

                <div>
                    <router-link to="/fauxplafonds" class="cta">
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#arrow_right"/>
                        </svg>
                        <span> Faux plafonds </span>
                    </router-link>
                </div>

                <div>
                    <router-link to="/menuiseries" class="cta">
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#arrow_right"/>
                        </svg>
                        <span> Menuiseries intérieures </span>
                    </router-link>
                </div>

                <div class="meuble">
                    <router-link to="/meubles" class="cta">
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#arrow_right"/>
                        </svg>
                        <span> Meubles et faux plafonds décoratifs  </span>
                    </router-link>
                </div>
            </div>

        </article>

        <footer class="js-reveal-bottom">
            <span>Une question ?</span>
            <a href="mailto:ipdeco35@gmail.com">
                <bouton>
                    Sollicitez-nous
                </bouton>
            </a>
        </footer>

    </section>

</template>

<script>
import Bouton from '../componentschild/Boutons.vue';
import ScrollReveal from 'scrollreveal';

export default {
    
    name: 'QuiNousSommes',
    components: {Bouton},
    methods: {
        changeRoute(route) {
        this.$router.push(route);
        }
    },
    mounted() {
        const revealBottom = {
            distance: '50%',
            delay: '600',
            duration: '1400',
            easing: 'cubic-bezier(.73, .35, .44, .9)',
            origin: 'bottom'
        };

        ScrollReveal().reveal('.js-reveal-bottom', revealBottom);
    }

}

</script>

<style scoped>

    .who {
        padding: var(--padding-top-bottom-section) var(--padding-left-right-section);
    }

    header {
        display: inline-flex;
        flex-direction: column;
    }

    header > h2::after {
        content: "";
        display: block;
        background-color: var(--color-secondary);
        width: 100%;
        height: var(--h2-underlying-height);
        margin-top: 4rem;
    }

    article {
        display: flex;
        flex-direction: column;
        align-items: center;
		padding-top: var(--spacing-top-big);
    }

    .description {
		font-size: var(--font-size-big);
		line-height: var(--line-height-big);
		width: 70%;
	}

    .description > span {
		font-weight: var(--font-weight-bold);
	}

    article > iframe {
        padding-top: var(--spacing-top-big);
        width: 70%;
        height: 70vh;
    }

    .details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-top: var(--spacing-top-big);
        width: 50%;
    }

    .details > div:not(:nth-child(5)) {
        width: calc(100% / 2);
    }

    .details > div:nth-child(n + 3) {
        margin-top: 4rem;
    }

    .meuble {
        width: 100%;
    }

    .cta {
        display: inline-flex;
        align-items: center;
    }

    .cta:hover {
        text-decoration: underline;
    }

    .cta > svg {
        width: 2rem;
        height: 2rem;
        fill: var(--color-secondary);
        flex-shrink: 0;
    }

    .cta > span {
        font-size: var(--font-size-big);
		line-height: var(--line-height-big);
        margin-left: 2rem;
    }

    footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8rem;
    }

    footer > span {
        font-size: var(--font-size-big);
		line-height: var(--line-height-big);
    }

    footer > a {
        margin-top: var(--spacing-top-medium);
    }


    @media screen and (max-width: 850px) {

        .who {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        header, article {
            width: 80%;
        }

        header > h2 {
            width: 100%;
            text-align: center;
        }

        .description {
            width: unset;
        }

        article > iframe {
            height: 60vh;
            width: 100%;
        }

        .details {
            width: 85%;
        }

    }

    @media screen and (max-width: 650px) {

        header, article {
            width: 100%;
        }

    }

    @media screen and (max-width: 450px) {

        .details {
            width: 100%;
        }

        .cta > span {
            margin-left: 1rem;
        }

    }

    @media screen and (max-width: 400px) {

        article > iframe {
            height: 40vh;
        }

    }

    @media screen and (max-width: 350px) {

        article > iframe {
            height: 35vh;
        }

    }

</style>