<template>
    
    <section class="devis-modale"  @click.self="toggleModale">

        <section class="form" :class="{ loading: isLoading,  flash: hasAlert }">
            <header>
                <h2> Devis </h2>
                <button class="close" @click="toggleModale">
                    <svg role="img" viewBox="0 0 32 32">
                        <use xlink:href="../assets/sprite.svg#close"/>
                    </svg>
                </button>
            </header>

            <div class="main">
                <p>Complétez ce formulaire et nous reviendrons vers vous dès que possible.</p>
                <section class="fields">
                    <div class="col-left">

                        <section class="identity">

                            <div>
                                <article>
                                    <input @input="removeError" ref="lastName" type="text" required>
                                    <label>Nom</label>
                                </article>
                                <p ref="lastNameError" class="error-text"></p>
                            </div>

                            <div>
                                <article>
                                    <input @input="removeError" ref="firstName" type="text" required>
                                    <label>Prénom</label>
                                </article>
                                <p ref="firstNameError" class="error-text"></p>
                            </div>

                        </section>
                        
                        <article>
                            <input @input="removeError" ref="email" type="text" required>
                            <label>Email</label>
                        </article>
                        <p ref="emailError" class="error-text"></p>

                        <article>
                            <input @input="removeError" ref="phone" type="tel" required>
                            <label>Téléphone</label>
                        </article>
                        <p ref="phoneError" class="error-text"></p>

                        <article>
                            <input @input="removeError" ref="address" type="text" required>
                            <label>Adresse</label>
                        </article>
                        <p ref="addressError" class="error-text"></p>

                    </div>

                    <div class="col-right">
                        <article>
                            <textarea @input="removeError" ref="message" cols="30" rows="10" required></textarea>
                            <label>Message</label>
                        </article>
                        <p ref="messageError" class="error-text"></p>
                    </div>
                </section>
            </div>

            <footer>
                <button @click="handlerDevis" type="button">Demander mon devis</button>
            </footer>

            <section class="spinner-container">
                <div class="spinner"></div>
            </section>

            <section class="alert-container">
                <span ref="alert" class="alert" :class="{ error: isAlertError, success: isAlertSuccess }">Votre message a bien été envoyée, nous reviendrons vers vous rapidement.</span>
            </section>
        </section>

    </section>

</template>

<script>
import axios from 'axios';

axios.defaults.validateStatus = (status) => { 
    return (status >= 200 && status < 300) || (status >= 400 && status < 600);
};

export default {
    name: 'DemandeDevis',
    props: ['revele', 'toggleModale'],
    data() {
        return {
            isLoading: false,
            hasAlert: false,
            isAlertError: false,
            isAlertSuccess: false,
            formDisplay: true
        }
    },
    methods: {
        removeError(event) {

            const { target } = event;
            const { nextSibling: errorElement } = target.parentElement;

            const classError = 'error';
            const classActive = 'active';

            if (!target.classList.contains(classError)) return;

            target.classList.remove(classError);
            errorElement.classList.remove(classActive);
            errorElement.textContent = '';

        },

        isErrorOnInput() {

            const { firstName, lastName, email, phone, message, address } = this.$refs;
            const { firstNameError, lastNameError, emailError, phoneError, messageError, addressError } = this.$refs;

            const classError = 'error';
            const classActive = 'active';

            let isError = false;

            if (!firstName.value.trim()) {
                firstName.classList.add(classError);
                firstNameError.classList.add(classActive);
                firstNameError.textContent = 'Veuillez renseigner votre prénom.';
                isError = true;
            }

            if (!lastName.value.trim()) {
                lastName.classList.add(classError);
                lastNameError.classList.add(classActive);
                lastNameError.textContent = 'Veuillez renseigner votre nom.';
                isError = true;
            }

            if (!email.value.trim()) { //|| validator.isEmail
                email.classList.add(classError);
                emailError.classList.add(classActive);
                emailError.textContent = 'Veuillez entrer un email valide.';
                isError = true;
            }

            if (!phone.value.trim()) {
                phone.classList.add(classError);
                phoneError.classList.add(classActive);
                phoneError.textContent = 'Veuillez renseigner votre numéro.';
                isError = true;
            }

            if (!address.value.trim()) {
                address.classList.add(classError);
                addressError.classList.add(classActive);
                addressError.textContent = 'Veuillez renseigner votre adresse.';
                isError = true;
            }

            if (!message.value.trim()) {
                message.classList.add(classError);
                messageError.classList.add(classActive);
                messageError.textContent = 'Veuillez renseigner votre message.';
                isError = true;
            }

            return isError;

        },

        async sendDevis() {

            const { firstName, lastName, email, phone, message, address } = this.$refs;

            try {

                const data = {
                    firstName: firstName.value,
                    lastName: lastName.value,
                    email: email.value,
                    phone: phone.value,
                    address: address.value,
                    message: message.value
                };

                return await axios.post('http://127.0.0.1:3000/api/v1/devis', data);

            } catch (err) {
                console.error(`Oups, quelque chose s'est mal passé. Veuillez réessayer plus tard!`);
            }

        },

        async handlerDevis() {

            try {
                const isError = this.isErrorOnInput();

                if (isError) return;

                this.toggleLoading();

                const rep = await this.sendDevis();

                if (rep.status >= 400 && rep.status < 500) return this.displayClientErrors(rep.data.data.errors);

                if (rep.status >= 500) return this.displayAlert('error', rep.data.data.message);

                this.displayAlert('success', rep.data.data.message);

            } catch (err) {
                console.error(`Oups, quelque chose s'est mal passé. Veuillez réessayer plus tard!`);
            }

        },

        displayClientErrors(errors) {

            const { firstName, lastName, email, phone, message, address } = this.$refs;
            const { firstNameError, lastNameError, emailError, phoneError, messageError, addressError } = this.$refs;

            const classError = 'error';
            const classActive = 'active';

            if (errors.firstName) {
                firstName.classList.add(classError);
                firstNameError.classList.add(classActive);
                firstNameError.textContent = errors.firstName;
            }

            if (errors.lastName) {
                lastName.classList.add(classError);
                lastNameError.classList.add(classActive);
                lastNameError.textContent = errors.lastName;
            }

            if (errors.email) {
                email.classList.add(classError);
                emailError.classList.add(classActive);
                emailError.textContent = errors.email;
            }

            if (errors.phone) {
                phone.classList.add(classError);
                phoneError.classList.add(classActive);
                phoneError.textContent = errors.phone;
            }

            if (errors.address) {
                address.classList.add(classError);
                addressError.classList.add(classActive);
                addressError.textContent = errors.address;
            }

            if (errors.message) {
                message.classList.add(classError);
                messageError.classList.add(classActive);
                messageError.textContent = errors.message;
            }

            this.toggleLoading();

        },

        displayAlert(type, message) {

            const { alert } = this.$refs;

            if (type === 'success') {
                alert.textContent = message;
                this.hasAlert = !this.hasAlert;
                this.isAlertSuccess = !this.isAlertSuccess;
                this.toggleLoading();

                return;
            }

            alert.textContent = message;
            this.hasAlert = !this.hasAlert;
            this.isAlertError = !this.isAlertError;
            this.toggleLoading();

        },

        toggleLoading() {
            this.isLoading = !this.isLoading;
        }
    },
    unmounted() {
        this.isLoading = false;
        this.hasAlert = false;
        this.isAlertError = false;
        this.isAlertSuccess = false;
    }

}
</script>

<style scoped>

.devis-modale {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-width: 100vw;
    background-color: var(--color-background-modal);
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-base);

    --padding-input-main: 1.5rem;
    --padding-input-bottom: 1rem;
}

.form {
    width: 60%;
    background-color: var(--color-white);
    z-index: 20;
    border-radius: 2rem;
    padding: 4rem;
    position: relative;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.close {
    cursor: pointer;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-round);
    transition: background-color 0.2s;
}

.close:hover {
    background-color: var(--color-grey);
}

.close:active {
    transform: scale(0.98);
}

.close svg {
    fill: var(--color-primary);
    width: 2rem;
    height: 2rem;
}

.main {
    margin-top: 2rem;
    transition: opacity 0.2s, visibility 0.2s;
}

.main > p {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
}

.fields {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 6rem;
}

.col-left, .col-right {
    width: calc((100% - 4rem) / 2);
}

.col-right {
    display: flex;
    flex-direction: column;
}

.identity {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.identity > div {
    width: calc((100% - 2rem) / 2);
}

.fields article {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fields article::after {
    display: block;
    content: '';
    width: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.2s;
}

.fields article:focus-within::after {
    width: 100%;
}

.col-left > article {
    margin-top: 1.5rem;
}

.fields input, textarea {
    width: 100%;
    padding: var(--padding-input-main) var(--padding-input-main) var(--padding-input-bottom) 0;
    border-bottom: 0.1rem solid var(--color-secondary);
    font-size: inherit;
}

.col-left label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: top 0.2s, font-size 0.2s, padding 0.2s, transform 0.2s;
}

.col-right label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: var(--padding-input-main);
    transition: top 0.2s, font-size 0.2s, padding 0.2s, transform 0.2s;
}

.fields input:focus ~ label, input:valid ~ label, textarea:focus ~ label, textarea:valid ~ label {
    font-size: 1rem;
    padding: 0 0 0.5rem 0;
    top: 0;
    transform: unset;
    color: var(--color-secondary);
}

.col-right article {
    flex: 1;
}

.col-right textarea {
    height: 100%;
}

footer {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 0.2s, visibility 0.2s;
}

footer button {
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border-radius: 0.5rem;
    color: var(--color-white);
    cursor: pointer;
    font-size: inherit;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

footer button:hover {
    background-color: var(--color-secondary);
}

footer button:active {
    transform: scale(0.98);
}

/* LOADER */
.form.loading .main, .form.loading footer {
    opacity: 0;
    visibility: hidden;
}

.form.loading .spinner-container {
    opacity: 1;
    visibility: visible;
}

.spinner-container, .alert-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
}

.spinner {
    width: 8rem;
    height: 8rem;
    border: 0.2rem solid var(--color-grey);
    border-right: 0.2rem solid var(--color-tertiary);
    border-radius: var(--border-radius-round);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* ALERT */
.form.flash .main, .form.flash footer {
    opacity: 0;
    visibility: hidden;
}

.form.flash .alert-container {
    opacity: 1;
    visibility: visible;
}

.form.flash .alert {
    top: 0;
    opacity: 1;
    visibility: visible;
}

.alert {
    position: relative;
    top: -15%;
    font-size: var(--font-size-base);
    border-radius: 1rem;
    padding: 1.5rem;
    line-height: var(--line-height-base);
    opacity: 0;
    visibility: hidden;
    transition: top 0.4s, opacity 0.4s, visibility 0.4s;
    transition-delay: 0.6s;
}

.alert.success {
    background-color: var(--color-success-light);
    color: var(--color-success);
}

.alert.error {
    background-color: var(--color-error-light);
    color: var(--color-error);
}


/* ERRORS */
.error-text {
    font-size: 10px;
    color: var(--color-error);
    transition: padding 0.2s;
}

.error-text.active {
    padding-top: 5px;
}

.input-class .input.error,
.msg textarea.error {
    border-bottom: 2px solid var(--color-error);
}

@media screen and (min-width: 1500px) {

    .form {
        width: 50%;
    }

    footer {
        margin-top: 4rem;
    }

}

@media screen and (max-width: 1000px) {
    .form {
        width: 70%;
    }
}

@media screen and (max-width: 750px) {
    .form {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .form {
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .fields {
        flex-direction: column;
    }

    .col-left, .col-right {
        width: 100%;
    }

    .col-left > article,
    .col-right, 
    .identity {
        margin-bottom: auto;
        margin-top: 0;
    }

    .devis-modale {
        height: 100%;
    }

}

@media screen and (max-width: 400px) {
    .form {
        width: 100%;
        height: 100%;
        border-radius: unset;
        padding: 2rem;
    }

    .form, .main, .col-left {
        display: flex;
        flex-direction: column;
    }

    .main, .fields, .col-left, .col-right {
        flex: 1;
    }

    .close {
        padding: 1rem;
    }
}

@media screen and (max-width: 350px) {

    header h2 {
        font-size: 2.3rem;
    }
    
    .main > p {
        text-align: center;
    }
}

</style>