<template>

	<section class="hero">

		<section class="offre js-reveal-left">
			<h1>Plâtrier / Plaquiste</h1>
			<p class="description">
				Iso Plac Deco est spécialisée dans tous les travaux d’isolation 
				et de pose de plaques de plâtre, <span> neuf et rénovation</span>. 
				Elle intervient aussi bien chez les particuliers que 
				chez les professionnels dans le département d'Ille-et -Vilaine (35).
				<br><br>
				Ouvert du Lundi au Samedi de 8:00 à 19:00.
			</p>
			
			<a href="mailto:ipdeco35@gmail.com">
				<bouton>
					Nous contacter
				</bouton>
			</a>

		</section>

		<section class="couverture js-reveal-right">
			<img src="../assets/photoaccmotifs.svg" alt="Image accueil">
		</section>

		<a class="cta-mobile" href="mailto:ipdeco35@gmail.com">
			<bouton>
				Nous contacter
			</bouton>
		</a>

	</section>

</template>

<script>
import Bouton from '../componentschild/Boutons.vue';
import ScrollReveal from 'scrollreveal';

export default {

  name: 'Accueil',
  components: {
    Bouton,
  },
  mounted() {
	const revealLeft = {
		distance: '50%',
		delay: '600',
		duration: '1400',
		easing: 'cubic-bezier(.73, .35, .44, .9)',
		origin: 'left'
	};

	const revealRight = {
		distance: '50%',
		delay: '600',
		duration: '1400',
		easing: 'cubic-bezier(.73, .35, .44, .9)',
		origin: 'right'
	};

	ScrollReveal().reveal('.js-reveal-left', revealLeft);
	ScrollReveal().reveal('.js-reveal-right', revealRight);
   }

}
</script>


<style scoped>

	.hero {
		height: calc(100vh - var(--nav-max-height));
		padding: 0 var(--padding-left-right-section);
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
	}

	.offre, .couverture {
		width: 50%;
	}

	.offre > h1::after {
		content: "";
		display: block;
		background-color: var(--color-secondary);
		width: 70%;
		height: var(--h1-underlying-height);
		margin-top: 4rem;
	}

	.description {
		padding-top: var(--spacing-top-big);
		font-size: var(--font-size-big);
		line-height: var(--line-height-big);
		width: 90%;
	}

	.description > span {
		font-weight: var(--font-weight-bold);
	}

	.offre > a {
		display: inline-flex;
		border-radius: var(--border-radius-btn);
		margin-top: var(--spacing-top-big);
	}

	.couverture > img {
		width: 100%;
	}

	.cta-mobile {
		display: none;
		margin-top: var(--spacing-top-big);
	}

	@media screen and (max-width: 850px) {

		.hero {
			flex-direction: column;
			padding-top: 8rem;
			justify-content: unset;
			height: unset;
			padding: var(--padding-top-bottom-section) var(--padding-left-right-section);
		}

		.offre {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.offre > h1 {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
		}

		.offre > h1::after {
			width: 80%;
		}

		.description {
			text-align: center;
			width: 80%;
		}

		.offre > a {
			display: none;
		}

		.couverture {
			width: 80%;
			padding-top: 4rem;
		}

		.cta-mobile {
			display: inline-flex;
		}

    }

	@media screen and (max-width: 650px) {
		.offre > h1::after {
			width: 100%;
		}

		.description {
			text-align: center;
			width: 100%;
		}

		.couverture {
			width: 100%;
			padding-top: 4rem;
		}
	}

</style>
