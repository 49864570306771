<template>

    <div class="englobecarrousel">
        <div class="boutongauche">
            <button @click="left" type="button" class="btn g"> <img alt="fleche-gauche" src="../assets/Flechegauche.svg"> </button>
        </div>
        <div class="carrouselvrai">
            <slot></slot> 
        </div>
        <div class="boutondroite">
            <button @click="right" type="button" class="btn d"> <img  alt="fleche-droite" src="../assets/Flechedroite.svg"> </button>
        </div>
    </div>

</template>

<script>

export default {
    name: 'Carrousel',
    data() {
        return {

        }
    },

    methods: {
        left() {
            this.$emit('left');
        },
        right() {
            this.$emit('right');
        }

    }
}

</script>

<style scoped>

    .englobecarrousel {

        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .carrouselvrai {

        height: 400px;
        display: flex;
        justify-content: center;

    }

    .btn {

        background-color: #7C928F;
        border: none;
        cursor: pointer;
        height: 60px;
        width: 60px;
        background-color: #7C928F;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

    }

    .btn d {

        right: 0;

    }

    .btn g {

        left: 0;

    }

        @media screen and (max-width: 1100px) {

        .btn  {

            height: 40px;
            width: 40px;

        } 

        .btn img {

            height: 16px;
            width: 16px;

        }

        }

        @media screen and (max-width: 930px) {

            .btn {

                height: 40px;
                width: 40px;

            }
            
            .btn img {

                height: 14px;
                width: 14px;

            }

        }

        @media screen and (max-width: 850px) {

            .englobecarrousel {

                margin-top: 60px;

            }

        }

        @media screen and (max-width: 580px) {

            .btn {

                height: 24px;
                width: 24px;
                border-radius: 12px;

            } 

            .btn img {

                height: 11px;
                width: 11px;

            }

        }

</style>


