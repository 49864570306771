<template>

    <section id="presentation">
        <nav>
            <section class="marque">
                <a href="/#presentation">
                <img alt="logo iso plac deco" src="../assets/logovert.png">
                </a>
                <p>La meilleure solution pour vos travaux</p>
            </section>
            
            <section class="liens">
                <section class="infos">

                    <a class="lien-top" role="button" href="tel:+33612345678">
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#phone"/>
                        </svg>
                        <span>06 50 98 31 19</span>
                    </a>

                    <a v-on:click="toggleModale" class="lien-top" role="button">
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#email"/>
                        </svg>
                        <span>Demander un devis</span>
                    </a>  

                    <div class="lien-top">
                        <svg role="img" viewBox="0 0 32 32">
                            <use xlink:href="../assets/sprite.svg#watch"/>
                        </svg>
                        <span>Lundi au Samedi 8:00 - 19:00</span>
                    </div>

                    <!-- <demande-devis v-bind:revele="revele" v-bind:toggleModale="toggleModale"></demande-devis> -->
                    <demande-devis v-if="revele" v-bind:toggleModale="toggleModale"></demande-devis>

                </section>

                <section class="menu-landing">
                    <router-link class="stylenav" to="/#presentation"> Présentation </router-link>
                    <router-link class="stylenav" to="/#creations"> Nos réalisations </router-link>
                    <router-link class="stylenav" to="/#quisommesnous"> Qui sommes-nous ? </router-link>
                    <router-link class="stylenav" to="/#avantapres"> Avant Après </router-link>
                    <router-link class="stylenav" to="/#temoignages"> Témoignages </router-link>
                    <router-link class="stylenav" to="/#contact"> Contact </router-link>
                </section> 
        
            </section>

            <section class="menunav">
                <div v-on:click="toggleMenuHamburger" class="boutonmenu">
                    <span> Menu </span>
                </div>  
                <menu-hamburger  v-bind:reveler="reveler" v-bind:toggleMenuHamburger="toggleMenuHamburger"></menu-hamburger> 
            </section>  

        </nav>

    </section>

</template>


<script>

import DemandeDevis from '../componentschild/DemandeDevis.vue';
import MenuHamburger from '../componentschild/MenuHamburger.vue';


export default {

    name: 'BarreNavigation',
    data() {
        return {
            revele: false,
            reveler: false,
        }
    },
    components: {

        DemandeDevis,
        MenuHamburger,

    },
    methods: {

        toggleModale(){
            this.revele = !this.revele
        },
        toggleMenuHamburger(){
            this.reveler = !this.reveler
        },

    }

}

</script>

<style scoped>

    #presentation {
        padding: 0 var(--padding-left-right-section);
        max-height: var(--nav-max-height);
    }

    #presentation > nav {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-grey);
    }

    .marque {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .marque > a img {
        width: 10rem;
    }

    .marque > p {
        padding-top: 0.5rem;
        font-size: 2.5rem;
        font-family : 'Beautyous';
    }

    .liens {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .infos, .menu-landing {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
    }

    .menu-landing {
        align-items: flex-end;
    }

    .infos svg {
        margin-right: 1rem;
        width: 3rem;
        height: 3rem;
        fill: var(--color-secondary);
    }

    .infos > a {
        border-radius: 1rem;
        transition: background-color 0.2s;
    }

    .infos > a:hover {
        background-color: var(--color-grey);
    }

    .lien-top {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        font-size: var(--font-size-base);
    }

    .lien-top:nth-child(n + 2) {
        margin-left: 4rem;
    }

    .menu-landing > a {
        font-size: var(--font-size-base);
        font-weight: 600;
    }

    .menu-landing > a:not(:first-child) {
        margin-left: 4rem;
    }

    .menu-landing > a:hover {
        text-decoration: none;
    } 

    .stylenav {

        color: var(--color-primary);
        position: relative;

    }

    .stylenav:before,
    .stylenav:after {
        content: "";
        position: absolute;
        height: 1px;
        background: var(--color-tertiary);
        opacity: 0;
        left: 50%;
        transform: translate(-50%, 12px);
        transition: all 0.2s ease-in-out;
    } 

    .stylenav::before {
        width: 90%;
        top: 2px;
    }

    .stylenav::after {
        width: 60%;
        top: 6px;
    }

    .stylenav:hover::before, 
    .stylenav:hover::after {
        opacity: 1;
        transform: translate(-50%, 14px);
    }

    .stylenav::before, 
    .stylenav:hover::after {
        transition-delay: 0.1s;
    }
    .stylenav:hover::before {
        transition-delay: 0s;
    }

    @media screen and (min-width: 870px) {

        .menunav {

            display: none;

        }


    }

    @media screen and (max-width: 870px) {

        .menunav {

            visibility: visible;

        }

        .menu-landing {

            display: none;


        }

        .liens {

            width: 300px;
            margin-top: 6px;

        }

        .infos {

            display: flex;
            flex-direction: column;
            width: 240px;  
            margin: 0 auto;

        }

        #presentation > nav {

            display: flex;          
            justify-content: center;
            align-items: center;

        }

    }

    @media screen and (min-width: 1400px) {

        .stylenav::before {
            top: 4px;
        }

        .stylenav::after {
            top: 8px;
        }

    }

    @media screen and (min-width: 850px) and (max-width: 1000px) {

        .stylenav::before {
            top: 0px;
        }

        .stylenav::after {
            top: 4px;
        }

    }


    @media screen and (max-width: 670px) {

        #presentation > nav {

            flex-direction: row;
            flex-wrap: wrap; 
        
        }

        .menunav {

            padding-top: 10px;

        }

        #presentation {
            
            max-height: none ;

        }

    }

    .toggle-button {

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 20px;
        cursor: pointer;

    }

    .bar {

        height: 3px;
        width: 100%;
        background-color: #333F50;

    }

    .boutonmenu {

        width: 60px;
        height: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #333F50;
        cursor: pointer;

    }

    .boutonmenu span {

        font-size: 15px;
        color: #333F50;

    }

</style>