import { createApp } from 'vue';
import App from './App.vue'
import router from './router/router.js'
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App).use(router).use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDo100N11vUJzobJ-xjWgp6AQMrWUm-998',
    },
}).mount('#app')
