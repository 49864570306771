<template>
    <section class="creations" id="creations">

        <header class="titresection">
            <titres-sections titre="Nos Réalisations"></titres-sections>
        </header>

        <div class="carrousel">
            <div class="slider">
                <carrousel
                @left="left"
                @right="right"
                >

                    <carrousel-slide v-for="(slide, index) in slides" :key="slide" 
                    :index="index"
                    :visibleSlide="visibleSlide">
                    <img alt="nosrealisations" class="imgcarrousel" 
                    :src="slide"/>
                    </carrousel-slide>   
                    <carrousel-slide v-for="(slide, index) in slidess" :key="slide" 
                    :index="index"
                    :visibleSlide="visibleSlide">
                    <img alt="nosrealisations" class="imgcarrousel" id="carrouselparun"
                    :src="slide"/>
                    </carrousel-slide>     
                    
                </carrousel>
            </div>              
        </div>
    </section>
</template>

<script>
import Carrousel from '../componentschild/Carrousel.vue';
import CarrouselSlide from '../componentschild/CarrouselSlide.vue';
import TitresSections from '../componentschild/TitresSections.vue';

export default {
    name: 'Creations',
    components: { TitresSections, Carrousel, CarrouselSlide },
    data: function(){

        return {

                slides: [
                    require('../assets/separation.png'),
                    require('../assets/creacirculaire.png'),
                    require('../assets/baie.png'),
                    require('../assets/comble.png'),
                ],

                slidess: [
                    require('../assets/creationtv.png'),
                    require('../assets/sallebain.png'),
                    require('../assets/lumierebleu.png'),
                    require('../assets/meubletv.png'),
                ],

            
            visibleSlide: 0,

        }
    },

    computed: {
        slidesLen() {
            return this.slides.length;
        }
    },

    methods: {
        left() {
            if(this.visibleSlide >= this.slidesLen - 1) {
                this.visibleSlide = 0;
            } else {
                this.visibleSlide++;
            }
        },
        right() {
            if(this.visibleSlide <= 0) {
                this.visibleSlide = this.slidesLen - 1;
            } else {
                this.visibleSlide--;
            }
        },
    },
}
</script>

<style scoped>

    .creations {

        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;

    }

    .carrousel {
    
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 100px;

    }

    .carrouselimg img {

        max-height: 94%;
        max-width: 94%;

    }

    .slider {

        width: 1200px;

    }

    @media screen and (max-width: 1000px) {

        .slider {
    
            margin: auto;
            width: 100%;

        }

        .carrousel {
    
            padding-top: 80px;

        }

        .carrouselimg img {

            max-height: 82%;
            max-width: 82%;

        }

    }

    @media screen and (max-width: 850px) {

        .carrousel {
    
            padding-top: 0px;

        }

        #carrouselparun {

            display: none;

        }

    }

    @media screen and (max-width: 580px) {

        .carrouselimg img {

            max-height: 72%;
            max-width: 72%;

        }

    }

    @media screen and (max-width: 400px) {

        .carrouselimg img {

            max-height: 80%;
            max-width: 80%;

        }

        .creations {

            padding-bottom: 0px;

        }

    }

</style>

/* right() {

            if (window.matchMedia("(max-width: 600px)").matches) {
                if(this.index === -960) {
                this.index = 0;
            } else {
                this.index -= 240;
            }

  /* La largeur minimum de l'affichage est 600 px inclus */
            } else if (window.matchMedia("(max-width: 1920px)").matches){
        
            if(this.index === -2000) {
                this.index = 0;
            } else {
                this.index -= 1000;
            }
            
            } else {
                
            if(this.index === -1920) {
                this.index = 0;
            } else {
                this.index -= 480;
            }

            }

            
    }    */ 


