<template>

    <section class="footer">

        <section>

            <div class="company">
                <h2>Iso plac deco <span class="tag">La meilleure solution pour vos travaux</span></h2>
                <p class="description">
                    Entreprise de plâtrier-plaquiste située à Bruz (Ille-et-Vilaine), Iso Plac Deco est spécialisée dans tous les travaux d’isolation et de pose de plaques de plâtre, neuf et rénovation. Elle intervient aussi bien chez les particuliers que chez les professionnels. <br> Iso Plac Deco réalise tous travaux d’aménagement intérieur avec plaques de plâtre sèches, pose de cloisons, doublage, isolation, pose de plafonds et faux-plafonds, aménagement de combles, réalisation de placards, de meubles et éléments décoratifs et pose de menuiseries intérieures. Elle réalise également les finitions telles que le ratissage et les bandes à joints.
                </p>
            </div>

            <div class="website">
                <span>Ce que nous faisons</span>
                <router-link class="cta" to="/isolation"> Isolation </router-link>
                <router-link class="cta" to="/amenagement"> Aménagement </router-link>
                <router-link class="cta" to="/fauxplafonds"> Faux plafonds </router-link>
                <router-link class="cta" to="/menuiseries"> Menuiseries </router-link>
                <router-link class="cta" to="/meubles"> Meubles </router-link>
            </div>

        </section>

        <section>

            <p class="copyright">
                © 2022 Iso Plac Deco. Tous droits réservés.
            </p>

            <div class="social">
                <a class="link" role="button" href="https://www.google.com/maps/dir//Iso+Plac+Deco,+4+Sq.+de+l'Abb%C3%A9+Pierre,+35170+Bruz/@48.0263619,-1.7569365,17z/data=!4m16!1m6!3m5!1s0x480f1f53d14b8491:0x3b581ca6055abcef!2sIso+Plac+Deco!8m2!3d48.0263619!4d-1.7547478!4m8!1m0!1m5!1m1!1s0x480f1f53d14b8491:0x3b581ca6055abcef!2m2!1d-1.7547478!2d48.0263619!3e0" rel="noopener noreferrer" target="_blank">
                    <svg role="img" viewBox="0 0 32 32">
                        <use xlink:href="../assets/sprite.svg#address"/>
                    </svg>
                </a>

                <a class="link" href="mailto:ipdeco35@gmail.com" role="button">
                    <svg role="img" viewBox="0 0 32 32">
                        <use xlink:href="../assets/sprite.svg#email"/>
                    </svg>
                </a>
                
                <a class="link" role="button" href="tel:+33650983119">
                    <svg role="img" viewBox="0 0 32 32">
                        <use xlink:href="../assets/sprite.svg#phone"/>
                    </svg>
                </a>
                
                <a class="link" role="button" href="https://fr-fr.facebook.com/pages/category/Contractor/Iso-Plac-Deco-354607558323818/" rel="noopener noreferrer" target="_blank">
                    <svg role="img" viewBox="0 0 32 32">
                        <use xlink:href="../assets/sprite.svg#facebook_logo"/>
                    </svg>
                </a>
            </div>

            <a href="#" class="scroll-btn">
                <div class="boutonremonte">
                    <img alt ="bouton-remonter" src="../assets/Vector.svg"> 
                </div>
            </a> 
        
        </section>

    </section>

</template>

<script>

export default {
    
    name: "footerpage",

    data() {
        return {
            revele: false
        }
    },
    
    methods: {

    toggleModale(){
            this.revele = !this.revele
    },

    } 

}

</script>

<style scoped>

    .footer {
        padding: var(--padding-top-bottom-section) var(--padding-left-right-section);
        background-color: var(--color-primary);
        color: var(--color-white);
    }

    .footer > section {
        display: flex;
        justify-content: space-between;
    }

    .footer > section:first-child {
        padding-bottom: 8rem;
        border-bottom: 0.1rem solid var(--color-white);
    }

    .company, .website {
        display: flex;
        flex-direction: column;
    }

    .company {
        width: 50%;
    }

    .company > h2 {
        display: flex;
        align-items: center;
    }

    .tag {
        font-weight: 300;
        font-size: 3rem;
        font-family: 'Beautyous';
        padding-left: 2rem;
    }

    .description {
        padding-top: 2rem;
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
    }

    .website > span {
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-bold);
        padding-bottom: 2rem;
    }

    .cta {
        display: inline-flex;
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
        margin-right: auto;
    }

    .cta:hover {
        text-decoration: underline;
    }

    .cta:not(:last-child) {
        margin-bottom: 2rem;
    }

    .footer > section:last-child {
        padding-top: 8rem;
        align-items: center;
    }

    .copyright {
        font-size: var(--font-size-medium);
    }

    .social {
        display: flex;
        align-items: center;
    }

    .link {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .link svg {
        height: var(--footer-icon-size);
        fill: var(--color-white);
    }

    .link:not(:first-child) {
        margin-left: 4rem;
    }

    .scroll-btn {      
        position: absolute;
    }

    .boutonremonte {
        background-color: white;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        position: fixed;
        right: 30px;
        bottom: 30px;
    }

    .boutonremonte img {
        height: 20px;
        width: 20px;
    }

    @media screen and (max-width: 850px) {

        .company {
            width: 60%;
        }

    }

    @media screen and (max-width: 700px) {

        .company > h2 {
            flex-direction: column;
            align-items: unset;
        }

        .tag {
            font-size: 3rem;
            font-family: 'Beautyous';
            padding-left: unset;
            padding-top: 1rem;
        }

        .description {
            padding-top: 4rem;
        }

    }

    @media screen and (max-width: 500px) {

        .footer > section {
            flex-direction: column;
        }

        .company {
            width: 100%;
            text-align: center;
        }

        .website {
            width: 100%;
            align-items: center;
            margin-top: 8rem;
        }

        .cta {
            margin-right: unset;
        }

        .social {
            margin-top: 4rem;
        }

    }

    @media screen and (max-width: 1200px) {

        .boutonremonte {
            height: 24px;
            width: 24px;
            position: fixed;
            right: 10px;
            bottom: 10px;
        }

        .boutonremonte img {
            height: 12px;
            width: 12px;
        }

    }

    @media screen and (max-width: 1000px) {

        .boutonremonte {
            height: 20px;
            width: 20px;
        }

        .boutonremonte img {
            height: 10px;
            width: 10px;
        }

    }

    @media screen and (min-width: 521px) and (max-width: 556px) {

        .copyright {
            font-size: 10px;
        }

    }

    @media screen and (min-width: 500px) and (max-width: 520px) {

        .copyright {
            font-size: 9px;
        }

    }

    @media screen and (max-width: 450px) {

        .boutonremonte {
            right: 6px;
            bottom: 6px;
        }

        .boutonremonte {
            height: 14px;
            width: 14px;
        }

        .boutonremonte img {
            height: 7px;
            width: 7px;
        }

    }

</style>